@import "../../../variables.scss";
@import "../../../index.scss";

.actions {
  @extend .flex-row;
  justify-content: space-evenly;
  align-items: center;
  margin-inline: 10px;
  height: 30px;

  button {
    @extend .flex-row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 5px 20px;
    gap: 10px;
    border-radius: 15px;
    color: $primary-color;
    transition: all ease-in-out 0.2s;
    position: relative;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75rem;
    }

    &:hover {
      background-color: $primary-color;
      color: $quaternary-color;
    }
  }
}
