@import "../../../index.scss";
@import "../../../variables.scss";

.person-container {
  @extend .flex-row;
  padding: 10px;
  justify-content: space-between;
  transition: all ease 0.2s;
  border-radius: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;

  .person-details {
    @extend .flex-row;
    gap: 10px;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 100%;
    }

    p:nth-child(1) {
      color: $primary-color;
      font-size: 1rem;
    }
    p:nth-child(2) {
      color: gray;
      font-size: 0.8rem;
      font-style: italic;
    }
  }

  &:hover {
    background-color: rgb(204, 204, 204);
  }
  button {
    background-color: transparent;
    height: 40px;
    padding-inline: 20px;
    border: none;
    font-size: 0.9rem;
    transition: all ease 0.2s;
    border-radius: 5px;
    color: $primary-color;

    &:hover {
      background-color: $primary-color;
      color: white;
    }
  }
}
