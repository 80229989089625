@import "../../../variables.scss";
@import "../../../index.scss";


.main-container{
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
}
.content-container{
    padding: 5px;
    min-height: 200px;
    // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.9);
}



