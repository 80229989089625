@import "../../variables.scss";
@import "../../index.scss";

.button-container {
  @extend .flex-row;
  flex: 1;
  width: 100%;
  gap: 10px;

  button {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 50px;
    border: 1px solid $secondary-color;
    background-color: $secondary-color;
    font-size: 15px;
    color: $quaternary-color;
    font-weight: bold;
    transition: all ease 0.2s;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      cursor: default;
      opacity: .6 !important;
    }
  }

  button:nth-child(1) {
    flex: 5;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
}
