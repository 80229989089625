@import "../../variables.scss";

.backdrop {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}

.blur {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.solid {
  background-color: $primary-color;
}
