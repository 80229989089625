@import "../../../variables.scss";

.text-input {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 14px;
  transition: all ease 0.1s;
  color: $secondary-color;

  &:focus {
    outline: none;
  }
}

.input-error {
  border: 3px solid $error-color;
}
