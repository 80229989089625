@import "../../../variables.scss";
@import "../../../index.scss";

form {
  @extend .flex-column;
  width: 100%;

  .form-control {
    @extend .flex-column;
    width: 100%;
    border: 1px solid $primary-color;
    padding: 3px 8px;
    border-radius: 10px;

    label {
      font-size: 15px;
      color: gray;
    }

    input,
    textarea {
      padding: 5px;
      border: none;
      border-radius: 0px;
      background-color: transparent;
      font-size: 0.8rem;
      max-height: 200px;
      color: $secondary-color;
      font-weight: bold;

      &:focus {
        outline: 0px;
      }
    }
  }

  button {
    background-color: $primary-color;
    border: none;
    color: white;
    padding: 5px;
    border-radius: 5px;
    transition: all ease 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }
}
