@import "../../../index.scss";
@import "../../../variables.scss";

.preview-post-image {
  @extend .flex-column;
  width: 100%;
  height: 100%;
  position: relative;

  img {
    object-fit: contain;
    width: 100%;
    aspect-ratio: 16/9;
    background-color: black;
  }

  button {
    @extend .flex-column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: 2px solid $primary-color;
    color: $primary-color;
    font-size: 1.1 rem;
    transition: all ease 0.2s;
    background-color: white;

    &:hover {
      background-color: $primary-color;
      color: white;
    }
  }

  #right {
    right: -15px;
    top: calc(50% - 10px);
  }

  #left {
    left: -15px;
    top: calc(50% - 10px);
  }

  #ppi__tracker {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: gray;
    bottom: 0;
    align-self: center;
    border-radius: 100px;
  }
}
