@import "../../index.scss";
@import "../../variables.scss";

.dropdown {
  @extend .flex-column;
  width: auto;
  height: auto;
  padding-block: 10px;
  background-color: white;
  box-shadow: 1px 1px 2px 1px lightgray;
  position: absolute;
  right: 0;
  top: calc(100% + 5px);
  border-radius: 10px;
}
