@import "../../../index.scss";
@import "../../../variables.scss";

.image-action {
  @extend .flex-column;
  width: 100px;
  button {
    @extend .flex-row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px 10px;
    background-color: white;
    color: $primary-color;
    border: none;
    transition: all ease 0.2s;

    &:hover {
      background-color: rgb(230, 230, 230);
    }
  }
}
