@import "./variables.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  @extend .page-size;
  font-family: "Poppins", sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.page-size {
  width: 100%;
  height: 100vh;
}

button {
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

img {
  background-color: black;
  color: black;
}

input {
  font-family: "Poppins", sans-serif;
}

a {
  color: $secondary-color;
  text-decoration: none;
  transition: all ease 0.2s;

  &:hover {
    text-decoration: underline;
  }
}
