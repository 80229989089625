@import "../../../index.scss";
@import "../../../variables.scss";

.add-comment {
  @extend .flex-row;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-evenly;
  border-top: 1px solid lightgray;
  padding-top: 10px;
  padding: 10px;

  textarea {
    width: 100%;
    border: none;
    padding: 5px 15px;
    font-size: 0.9rem;
    font-family: "Poppins", sans-serif;
    resize: none;
    max-height: 200px;
    background-color: $quaternary-color;
    border-radius: 10px;
    transition: all ease 0.2s;
    color: $primary-color;

    &:focus {
      outline: none;
      background-color: rgb(226, 224, 224);
    }
  }

  button {
    @extend .flex-column;
    width: 80px;
    height: 32px;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 2px solid $primary-color;
    padding-inline: 20px;
    border-radius: 15px;
    background-color: $primary-color;
    color: $quaternary-color;
    transition: all ease 0.2s;

    #btn-logo {
      font-size: 1rem;
      transform: rotateZ(90deg);
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
