@import "../../../index.scss";
@import "../../../variables.scss";

.no-post-msg {
  @extend .flex-column;
  width: 100%;
  height: 50px;
  background-color: lightgray;
  color: $primary-color;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
