@import "../../../index.scss";
@import "../../../variables.scss";

nav {
  @extend .flex-row;
  gap: 10px;
  align-items: center;

  .button-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    font-weight: bold;
    border: none;
    font-size: 15px;
    background-color: transparent;
    border: 2px solid $primary-color;
    color: $primary-color;
    transition: all ease 0.2s;

    &:hover {
      opacity: 0.5;
    }
  }
}
