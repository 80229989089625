@import "../../variables.scss";
@import "../../index.scss";

.settings-layout {
  @extend .flex-column;
  gap: 10px;

  section {
    @extend .flex-column;
    padding: 10px;

    h1 {
      color: $primary-color;
    }
  }
}
