@import "../../../variables.scss";
@import "../../../index.scss";

.edit-profile {
  @extend .flex-column;
  width: 500px;
  padding: 20px;
  border-radius: 5px;
  height: auto;
  max-height: 80%;
  background-color: white;
  overflow-y: auto;
  gap: 10px;

  h1 {
    color: $primary-color;
  }
}
