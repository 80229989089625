@import "../../../index.scss";
@import "../../../variables.scss";

.edit-post {
  @extend .flex-column;
  width: 500px;
  height: auto;
  background-color: white;
  padding: 20px;
  border-radius: 5px;

  header {
    @extend .flex-row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;

    h1 {
      color: $primary-color;
    }

    button {
      @extend .flex-row;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      border: none;
      transition: all ease 0.2s;
    }
  }
}
