@import "../../../../index.scss";
@import "../../../../variables.scss";

.image-container {
    @extend .flex-column;
    height: 100px;
    width: 32.9%;
    border-radius: 5px;
    position: relative;

    img {
      height: 100%;
      object-fit: cover;
      transition: all ease 0.2s;
      border-radius: 5px;

      &:hover {
        transform: scale(0.9);
      }
    }

    .last {
      @extend .flex-column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.305);
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      backdrop-filter: blur(2px);

      button {
        @extend .flex-column;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        font-weight: bold;
        border-radius: 50%;
        background-color: $dark-gray;
        color: white;
        border: none;
        transition: all ease 0.2s;

        &:hover {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          font-size: 25px;
        }
      }
    }
  }