@import "../../../index.scss";
@import "../../../variables.scss";

.person {
  @extend .flex-row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  padding: 20px 5px;
  border-radius: 50px;
  transition: all ease 0.2s;
  border: none;
  background-color: transparent;

  &:hover {
    background-color: rgb(237, 235, 235);
  }

  .profile {
    @extend .flex-row;
    gap: 10px;
    align-items: center;
    border: none;
    background-color: transparent;
    border-radius: 20px;
    transition: all ease 0.2s;
    font-size: 0.9rem;

    p {
      padding-right: 10px;
    }

    img {
      height: 30px;
      width: 30px;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  #follow-action {
    background-color: transparent;
    border: none;
    color: $primary-color;
    padding: 5px 20px;
    border-radius: 20px;
    transition: all ease 0.2s;
    font-size: .9rem;

    &:hover {
      background-color: $primary-color;
      color: white;
    }
  }
}
