@import "../../index.scss";
@import "../../variables.scss";

.portal {
  @extend .flex-column;
  @extend .page-size;
  flex: 1;

  section {
    padding: 20px;
  }

  .content {
    @extend .flex-row;
    flex: 15;

    section:nth-child(1) {
      display: flex;
      flex-wrap: wrap;
      flex: 5;
      flex-shrink: 1;
      padding: 0;
    }

    section:nth-child(2) {
      display: flex;
      flex-wrap: wrap;
      flex: 2;
      flex-shrink: 1;
      justify-content: center;
      align-items: center;
    }
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: $secondary-color;
  }
}
