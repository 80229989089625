@import "../../../index.scss";
@import "../../../variables.scss";

.dropdown-container {
  @extend .flex-column;
  position: absolute;
  top: 52px;
  right: 10px;
  background-color: white;
  padding-block: 5px;
  box-shadow: 0px 1px 1px 1px lightgray;
  border-radius: 5px;
  width: 125px;
  z-index: 2;

  .action-button {
    @extend .flex-row;
    width: 100%;
    height: auto;
    border-radius: 0px;
    font-size: 15px;
    padding-inline: 20px;
    gap: 5px;
    justify-content: space-between;

    &:hover {
      background-color: rgb(231, 231, 231);
      color: $primary-color;
    }
  }
}
