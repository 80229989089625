@import "../../../variables.scss";
@import "../../../index.scss";

.settings-form {
  @extend .flex-column;
  width: 60%;

  button:disabled {
    cursor: default;
    opacity: 0.8;
    background-color: gray;
  }

  .form-control {
    @extend .flex-column;

    label {
      display: flex;
      gap: 5px;
      align-items: center;
      margin: 0px 10px;
      font-size: 1rem;
      color: $primary-color;
    }

    input {
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid lightgray;
      font-weight: bold;
      color: $primary-color;
      transition: all ease 0.1s;

      &:focus {
        font-weight: normal;
        outline: 2px solid $primary-color;
      }

      &:disabled {
        color: gray;
      }
    }
  }
}
