@import "../../../../index.scss";

.empty {
  @extend .flex-column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  p {
    color: $primary-color;
  }
}
