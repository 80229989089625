@import "../../../variables.scss";
@import "../../../index.scss";

.profile-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.profile-link {
  position: relative;
  border: 5px solid white;
  border-radius: 50%;
  display: inline-block;
  margin: -50px 10px 0px 20px;
  width: 100px;
  height: 100px;
  vertical-align: bottom;
  background-color: black;
  cursor: pointer;

  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all ease 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }
}

.follow-btn {
  font-size: 0.8rem;
  border: 2px solid $secondary-color;
  border-radius: 25px;
  background-color: rgba(29, 164, 242, 0);
  padding: 3px 20px;
  margin: 10px;
  font-weight: bold;
  color: $secondary-color;
  font-weight: 700;
  outline: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $quaternary-color;
  }
}
