@import "../../../index.scss";
@import "../../../variables.scss";

.rounded-avatar {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease 0.2s;

  &:hover {
    opacity: 0.8;
  }

  .rounded-img {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
