@import "../../index.scss";

.card {
  @extend .flex-column;
  background-color: white;
  border: 1px solid gainsboro;
  box-shadow: 0px 1px 2px lightgray;
  border-radius: 5px;
  gap: 5px;
}
