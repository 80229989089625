@import "../../../index.scss";
@import "../../../variables.scss";

.comment {
  @extend .flex-column;
  width: 100%;
  height: auto;
  gap: 10px;
  background-color: rgb(243, 239, 239);
  padding: 10px;
  border-radius: 5px;

  .header {
    @extend .flex-row;
    width: auto;
    height: auto;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    div {
      p {
        font-size: .9rem;
      }

      p:nth-child(2) {
        font-size: .75rem;
        color: gray;
      }
    }
  }

  p {
    font-size: .9rem;
  }
}
