@import "../../index.scss";
@import "../../variables.scss";

.content-container {
  @extend .flex-row;
  width: 50%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all ease 0.2s;

  @include LGdesktop {
    width: 50%;
  }

  @include MDdesktop {
    width: 60%;
  }

  @include desktop {
    width: 70%;
  }

  @include LGtablet {
    width: 100%;
  }

  @include tablet {
    width: 90%;
  }
}
