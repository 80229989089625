@import "../../../index.scss";
@import "../../../variables.scss";

.main-container {
  @extend .flex-row;
  align-self: center;
  justify-content: center;

  .main-image-container {
    @extend .flex-column;
    width: 475px;
    height: 85vh;
    gap: 10px;
    position: relative;
    z-index: 5;
  }
}
