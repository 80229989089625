@import "../../../index.scss";
@import "../../../variables.scss";

.nav-container {
  display: flex;
  width: 100%;
  height: 60px;
  box-shadow: 1px 1px 3px 1px rgba(95, 95, 95, 0.5);
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: white;
  z-index: 2;

  .nav-content {
    @extend .flex-row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    padding-inline: 50px;

    .title-container {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .nav-btn-group {
      @extend .flex-row;
      gap: 10px;
      align-items: center;
      position: relative;
    }

    .nav-unAuth-btn-group {
      @extend .flex-row;
      gap: 10px;
      align-items: center;

      button {
        @extend .flex-column;
        height: 20px;
        padding: 20px;
        justify-content: center;
        align-items: center;
        transition: all ease 0.2s;
        border-radius: 5px;
        background-color: transparent;
        border: 2px solid $secondary-color;
        color: $secondary-color;
        font-weight: 700;

        &:hover {
          border-radius: 10px;
          background-color: $secondary-color;
          color: white;
        }
      }
    }
  }
}
