.main-image{
  display: flex;
  flex: 1;
  border-radius: 20;
  padding: 10px;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
}
