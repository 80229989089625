@import "../../../index.scss";
@import "../../../variables.scss";

.image-gallery {
  @extend .flex-column;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  gap: 10px;

  h1 {
    font-size: 1.25rem;
    color: $primary-color;
  }

  .images-container {
    @extend .flex-row;
    flex-wrap: wrap;
    gap: 2px;
    width: 100%;
  }

  .link {
    font-size: 14px;
    width: 75px;
    align-self: flex-end;
  }
}
