@import "../../../index.scss";
@import "../../../variables.scss";

.add-image-container {
  @extend .flex-column;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;

  button {
    @extend .flex-column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    border-radius: 0px 0px 10px 10px;
    color: $quaternary-color;
    background-color: $secondary-color;
    border: $secondary-color 2px solid;
    transition: all ease 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  section {
    @extend .flex-column;
    width: 100%;
    height: 300px;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid $secondary-color;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    padding: 10px;
    gap: 10px;
    border-bottom: 0px;
  }
}
