@import "../../../index.scss";
@import "../../../variables.scss";

.search-result {
  @extend .flex-column;
  width: 400px;
  max-height: 500px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  top: 35px;
  left: -50px;
  padding: 10px 0px;
  border-radius: 5px;
  border: 1px solid gainsboro;
  box-shadow: 0px 1px 2px lightgray;

  .no-result {
    padding: 5px 20px;
  }

  .header {
    @extend .flex-row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 5px;
    color: $primary-color;

    p {
      font-size: 20px;
      font-weight: bold;
    }

    #clear {
      border: none;
      width: 30px;
      height: 30px;
      padding: 0px;
      @extend .flex-column;
      justify-content: center;
      align-items: center;
      background-color: gainsboro;
      border-radius: 100%;
      transition: all ease 0.2s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
