@import "../../../index.scss";
@import "../../../variables.scss";

.suggested-people {
  @extend .flex-column;
  width: 100%;
  height: auto;
  padding: 15px;

  h1 {
    font-size: 1.25rem;
    color: $primary-color;
  }
}
