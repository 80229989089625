@import "../../variables.scss";
@import "../../index.scss";

.side-panel {
  @extend .flex-column;
  flex: 2;
  width: 100%;
  height: 100%;
  padding: 12px;
  gap: 5px;
  position: relative;
}
