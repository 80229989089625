@import "../../../index.scss";
@import "../../../variables.scss";

.create-post {
  @extend .flex-row;
  gap: 10px;
  padding: 15px;

  .avatar {
    display: flex;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
  }

  .text-input {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    background-color: $quaternary-color;
    padding: 8px;
    padding-inline: 20px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    transition: all ease 0.2s;
    color: gray;

    &:hover {
      opacity: 0.6;
    }
  }
}
