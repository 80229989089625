@import "../../index.scss";
@import "../../variables.scss";

.modal-overlay {
  @extend .flex-column;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
