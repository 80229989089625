@import "../../variables.scss";
@import "../../index.scss";

.main-panel {
  @extend .flex-column;
  flex: 3;
  width: 100%;
  height: 100%;
  gap: 10px;
  padding: 12px;
}
