@import "../../../variables.scss";

.title {
  font-family: "Ms Madi", cursive;
  font-size: 30px;
  cursor: pointer;
  user-select: none;
}

.white {
  color: white;
}

.default {
  color: $secondary-color;
}
