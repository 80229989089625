@import "../../../index.scss";

.header {
  @extend .flex-row;
  align-items: center;
  padding-inline: 20px;
  padding-top: 20px;
  position: relative;

  .details {
    @extend .flex-row;
    width: 100%;
    align-items: center;
    gap: 10px;

    .post-image {
      width: 35px;
      height: 35px;
      border-radius: 100%;
      object-fit: cover;
      cursor: pointer;
      transition: all ease .2s;

      &:hover {
        opacity: 0.8;
      }
    }

    .header-content {
      p:nth-child(1) {
        font-size: 1rem;
      }

      p:nth-child(2) {
        font-size: 0.75rem;
        color: gray;
      }
    }
  }

  button {
    align-self: flex-start;
    background-color: transparent;
    width: 30px;
    height: 30px;
    padding: 5px;
    font-size: 20px;
    color: $primary-color;
    @extend .flex-column;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    transition: all ease 0.2s;

    &:hover {
      background-color: $primary-color;
      color: white;
    }
  }
}
