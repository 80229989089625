@import "../../../index.scss";
@import "../../../variables.scss";

.action-button {
  @extend .flex-column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 60px;
  height: 60px;
  font-size: 20px;
  border-radius: 50%;
  border: 5px solid $primary-color;
}

#btn-left {
  top: calc(50% - 25px);
  left: -70px;
}

#btn-right {
  top: calc(50% - 25px);
  right: -70px;
}
