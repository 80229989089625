@import "../../../index.scss";
@import "../../../variables.scss";

.comments {
  @extend .flex-column;
  gap: 10px;
  padding: 0px 20px 10px 20px;

  h1 {
    color: $primary-color;
  }
}
