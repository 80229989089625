@import "../../index.scss";
@import "../../variables.scss";

.header {
  @extend .flex-row;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;

  button {
    background-color: transparent;
    @extend .flex-column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 2px solid $primary-color;
    color: $primary-color;
    font-size: 18px;
    transition: all ease 0.2s;

    &:hover {
      background-color: $primary-color;
      color: white;
    }
  }

  span {
    font-size: 1.5rem;
    font-weight: bold;
    color: $primary-color;
  }
}
