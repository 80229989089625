@import "../../../index.scss";
@import "../../../variables.scss";

.settings-form {
  @extend .flex-column;
  width: 60%;

  .form-control {
    @extend .flex-column;

    label {
      display: flex;
      gap: 5px;
      align-items: center;
      margin: 0px 10px;
      font-size: 1rem;
      color: $primary-color;
    }

    input {
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid lightgray;
      font-weight: bold;
      color: $primary-color;
      transition: all ease 0.1s;

      &:focus {
        font-weight: normal;
        outline: 2px solid $primary-color;
      }

      &:disabled {
        color: gray;
      }

      &::placeholder {
        font-weight: normal;
      }
    }
  }

  .action-buttons {
    @extend .flex-row;

    gap: 10px;
    width: 100%;

    button {
      @extend .flex-column;
      justify-content: center;
      align-items: center;
    }

    button:nth-child(1) {
      flex: 4;

      &:disabled {
        opacity: 0.6;
        cursor: default;
      }
    }
    button:nth-child(2) {
      flex: 1;
    }
  }
}
