@import "../../index.scss";
@import "../../variables.scss";

.post-header {
  @extend .flex-row;
  height: 50px;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;

  button {
    @extend .flex-row;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid $primary-color;
    background-color: transparent;
    transition: all ease 0.2s;
    font-size: 18px;
    color: $primary-color;

    &:hover {
      background-color: $primary-color;
      color: white;
    }
  }

  h1 {
    color: $primary-color;
  }
}
