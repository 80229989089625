@import "../../../index.scss";
@import "../../../variables.scss";

.main-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-color: black;
  border-radius: 15px;
  border: 5px solid $primary-color
}

.image-details {
  @extend .flex-column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  flex: 1;
  width: 100%;
  position: absolute;
  padding: 20px 20px;
  bottom: 0px;
  right: 0px;
  border-radius: 5px;

  p {
    color: white;
    margin-inline: 10px;
    font-size: 0.9rem;
    font-style: italic;
  }

  .view-post {
    position: relative;
    width: 100%;
    height: auto;
    font-size: 0.75rem;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: $secondary-color;
    color: white;
    transition: all ease 0.2s;

    &:hover {
      opacity: 0.9;
    }
  }
}
