@import "../../index.scss";
@import "../../variables.scss";

.settings-page-layout {
  @extend .flex-column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1 0px;
}
